import gql from "graphql-tag";
import {
  contactMethodFields,
  deviceFields,
  incidentFields,
  organizationFields,
  userFields,
} from "../fragments";

export const createZone = gql`
  mutation CreateZone(
    $name: String!
    $parent: ParentOrganization!
    $profile: InputZoneProfile!
    $unitTypeId: ID
    $subtype: String!
    $timeZone: String
  ) {
    createZone(
      name: $name
      parent: $parent
      type: "zone"
      subtype: $subtype
      profile: $profile
      unitTypeId: $unitTypeId
      timeZone: $timeZone
    ) {
      ...OrganizationFields
      parentId
    }
  }
  ${organizationFields}
`;

export const LIST_ORGANIZATION_DESCENDANTS = gql`
  query OrganizationDescendants(
    $id: ID!
    $searchText: String
    $type: String
    $types: [String]
    $limit: Int
  ) {
    organizationDescendants(
      id: $id
      searchText: $searchText
      type: $type
      types: $types
      limit: $limit
      sort: "name"
    ) {
      id
      name
      type
      parentId
      isDemo
    }
  }
`;

export const LIST_ORGANIZATION_ANCESTORS_FEATURES = gql`
  query OrganizationAncestorsFeatures($id: ID!, $type: String) {
    organization(id: $id) {
      features {
        disabled
        feature {
          name
        }
      }
      ancestors(type: $type) {
        id
        name
        features {
          disabled
          feature {
            name
          }
        }
        profile {
          ... on DefaultProfile {
            email
            extension
            phoneNumber
            autoEmailForScheduler
            enableTenantLedger
            tenantLedgerReportType
            enableRecurringchargeReconciliationReport
            reconciliationReportType
            autoEmailForReconciliationReport
          }
        }
      }
    }
  }
`;

export const ORGANIZATION_TREE = gql`
  query Organization($id: ID!, $expanded: Boolean) {
    organization(id: $id, expanded: $expanded, maxDepth: 1) {
      id
      name
      type
      children {
        id
        name
        type
      }
    }
  }
`;

export const organizationSmartZones = gql`
  query OrganizationSmartZones(
    $id: ID!
    $sort: String
    $incident_statuses: [String!] = ["closed", "muted"]
    $incident_sort: String = "closedAt desc"
    $incident_resolution: String = "denied"
  ) {
    smartZones: organizationDescendants(
      id: $id
      type: "zone"
      subtype: "zone"
      sort: $sort
    ) {
      subtype
      ...OrganizationFields
      devices {
        ...DeviceFields
      }
      incidents(
        sort: $incident_sort
        statuses: $incident_statuses
        resolution: $incident_resolution
      ) {
        id
        type
        status
        resolution
      }
      lastIncident: incidents(sort: "startedAt desc", limit: 1) {
        ...IncidentFields
      }
    }
  }
  ${deviceFields}
  ${organizationFields}
  ${incidentFields}
`;

export const addOrganizationDevices = gql`
  mutation addOrganizationDevices($id: ID!, $devices: [ID!]) {
    addOrganizationDevices(id: $id, devices: $devices) {
      ...DeviceFields
      organization {
        id
      }
    }
  }
  ${deviceFields}
`;

export const organizationResponders = gql`
  query OrganizationResponders($id: ID!, $includeAncestors: Boolean) {
    organizationResponders(id: $id, includeAncestors: $includeAncestors) {
      id
      escalationLevel
      incidentTypes
      contactMethod {
        ...ContactMethodFields
      }
      organization {
        ...OrganizationFields
      }
      user {
        ...UserFields
      }
    }
  }
  ${contactMethodFields}
  ${organizationFields}
  ${userFields}
`;

export const DEMO_SMART_UNIT_RESPONDERS = gql`
  query DemoSmartUnitResponders($id: ID!) {
    demoSmartUnitResponders: organizationDescendants(
      id: $id
      assigned: false
      type: "zone"
      subtype: "unit"
    ) {
      ...OrganizationFields
      responders {
        id
        demoEndingAt
        status
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
  ${organizationFields}
`;

export const activeSmartUnits = gql`
  query ActiveSmartUnits($id: ID!) {
    smartUnits: organizationDescendants(id: $id, assigned: true, type: "zone") {
      id
      devices {
        id
      }
    }
  }
`;

export const activeSmartUnitsDetailed = gql`
  query ActiveSmartUnitsDetailed($id: ID!) {
    smartUnits: organizationDescendants(id: $id, assigned: true, type: "zone") {
      ...OrganizationFields
      devices {
        ...DeviceFields
      }
      incidents(sort: "closedAt desc", authorized: false, limit: 1) {
        id
      }
      responders {
        id
        user {
          firstName
          lastName
          id
        }
        contactMethod {
          type
          value
        }
      }
    }
  }
  ${deviceFields}
  ${organizationFields}
`;

export const activeSmartZonesDetailed = gql`
  query activeSmartZonesDetailed(
    $id: ID!
    $incident_statuses: [String!] = ["closed"]
    $incident_sort: String = "closedAt desc"
    $incident_resolution: String = "denied"
  ) {
    smartZones: organizationDescendants(
      id: $id
      type: "zone"
      subtype: "zone"
    ) {
      ...OrganizationFields
      devices {
        ...DeviceFields
      }
      incidents(
        sort: $incident_sort
        statuses: $incident_statuses
        resolution: $incident_resolution
      ) {
        id
        type
        status
        resolution
      }
    }
  }
  ${deviceFields}
  ${organizationFields}
`;

export const ORGANIZATION_FEATURES = gql`
  query OrganizationFeatures($id: ID!) {
    organization(id: $id) {
      id
      name
      type
      features {
        id
        disabled
        insertedAt
        updatedAt
        feature {
          id
          name
        }
      }
    }
  }
`;

export const ORGANIZATION_ANCESTORS = gql`
  query OrganizationAncestors($id: ID!, $types: [String]) {
    organization(id: $id) {
      ancestors(types: $types) {
        id
        name
        type
      }
    }
  }
`;
