import { apolloWatchQuery } from "../../apollo-client/helper";
import { LIST_ORGANIZATION_ANCESTORS_FEATURES } from "../../graphql/organizations";
import { computed, ref, watchEffect } from "vue";
import useUser from "./useUser";
import { useRoute, useRouter } from "vue-router/composables";

const organizationAncestorsLoading = ref(false);
const organizationAncestors = ref(null); // Initialize as null to indicate "not fetched"
const features = ref(null);

export const FEATURES = {
  DASHBOARD: {
    MANAGEMENT_SUMMARY: "management_dashboard",
    MONITORING_AND_CONTROL_HUB: "monitoring_dashboard",
    OPERATIONS_AND_SECURITY: "operations_dashboard",
  },
  TASK_MANAGEMENT: "task_management",
  SETTINGS: {
    PRIORITY_MATRIX: "incident_priority_matrix",
    ESCALATION_MATRIX: "incident_escalation_matrix",
  },
};

export default function useAccess() {
  const { defaultOrganization, isFacilityManager } = useUser();

  async function fetchEffectiveFeatures(apolloClient) {
    organizationAncestorsLoading.value = true;
    apolloWatchQuery(
      apolloClient,
      {
        query: LIST_ORGANIZATION_ANCESTORS_FEATURES,
        variables: {
          id: defaultOrganization.value.id,
          type: "organization",
        },
        fetchPolicy: "no-cache",
      },
      ({ organization }, queryLoading) => {
        organizationAncestorsLoading.value = queryLoading;
        organizationAncestors.value = organization?.ancestors || [];

        const ancestorFeatures = organization.ancestors.flatMap(
          (ancestor) => ancestor.features
        );
        const orgFeatures = organization.features;
        features.value = [...ancestorFeatures, ...orgFeatures];
      }
    );
    organizationAncestorsLoading.value = false;
  }

  function canAccessFeature(feature) {
    if (features.value === null) {
      return false;
    }
    return features.value.some(
      (f) => f.feature.name === feature && !f.disabled
    );
  }

  function canAccessSettings() {
    if (features.value === null) {
      return false;
    }

    const hasPriorityMatrix = canAccessFeature(
      FEATURES.SETTINGS.PRIORITY_MATRIX
    );
    const hasEscalationMatrix = canAccessFeature(
      FEATURES.SETTINGS.ESCALATION_MATRIX
    );

    return hasPriorityMatrix && hasEscalationMatrix;
  }

  function createShowFeature(feature) {
    return computed(() => {
      return (
        !organizationAncestorsLoading.value &&
        features.value !== null &&
        !isFacilityManager.value &&
        canAccessFeature(feature)
      );
    });
  }

  const showManagementSummary = createShowFeature(
    FEATURES.DASHBOARD.MANAGEMENT_SUMMARY
  );
  const showOperationAndSecurity = createShowFeature(
    FEATURES.DASHBOARD.OPERATIONS_AND_SECURITY
  );
  const showTaskManagement = createShowFeature(FEATURES.TASK_MANAGEMENT);

  const showSettings = computed(() => {
    return (
      !organizationAncestorsLoading.value &&
      features.value !== null &&
      !isFacilityManager.value &&
      canAccessSettings()
    );
  });
  function restrictDisabledFeaturesRoutes() {
    const route = useRoute();
    const router = useRouter();
    const RESTRICTED_URLS = ["management_summary"];
    const currentUrl = computed(() => route.path);

    watchEffect(() => {
      if (features.value !== null) {
        const shouldRedirect = RESTRICTED_URLS.some((restrictedUrl) =>
          currentUrl.value.includes(restrictedUrl)
        );

        if (shouldRedirect && !showManagementSummary.value) {
          router.push({ name: "ManagementDashboard" });
        }
      }
    });
  }

  restrictDisabledFeaturesRoutes();

  return {
    fetchOrganizationFeatures: fetchEffectiveFeatures,
    organizationFeaturesLoading: organizationAncestorsLoading,
    canAccessFeature,
    showSettings,
    showManagementSummary,
    showOperationAndSecurity,
    showTaskManagement,
    organizationAncestors,
  };
}
