import PageEscalationMatrix from "./PageEscalationMatrix.vue";
const routes = {
  navigation: [
    {
      path: "/settings/escalation_matrix",
      name: "SettingsEscalationMatrix",
      component: PageEscalationMatrix,
    },
  ],
};

export default routes;
